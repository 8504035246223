import Vue from 'vue';
import Router from 'vue-router'
import Home from '@/components/Home'
import Page from '@/components/Page'

Vue.use(Router);


const router = new Router({
    // mode: 'history',
    routes: [
        {
            path: '/',
            name: 'homeView',
            component: Home,
        },
        {
            path: '/page/:id',
            name: 'pageView',
            component: Page,
        }
    ]

});

export default router
