
export let strObj = {
    title: '',//網頁標題
    searchIO:false,//搜尋欄位是否顯示(ture : 顯示 false : 不顯示 )
    guestServiceCoordinator: '派发员',
    inputpopularity:'请输入姓名为主播打榜',
    popularity:'打榜', //打榜
    donate:'助力主播',//助力主播
    donatePoints:'助力积分',//助力积分
    name:'姓名',//姓名
    donateSuccess:'打榜成功。',//打榜成功
    connectService:'请将本页面截屏后联系您的助手。',
    integral:'积分：',
    anchorlist:'主播列表',
    anchorProfile:'主播简介',
    giftgifShow: false, //打榜後 gif圖是否自訂義
    itemShow:true,//主播姓名是否顯示(true : 顯示 ; false:隱藏 )
    url:[],//輪播圖位址 (如果當只有一個位置的時候不啟動輪播)
    //rankingShow:this.url.length===0, //顯示排行或輪播圖 (true : 排行榜 ; false:輪播圖 )
    pleaseEnterName: '请输入姓名',
}
//客製化設定

export default function siteCustomized(){
    let siteId = window.siteId
    switch(siteId){
        case 7:
            strObj.donate = '帮助主播'
            strObj.donatePoints='帮助积分'
            strObj.searchIO = true;
            break;
        case 9:
            strObj.guestServiceCoordinator= "结算专员";
            break;
        case 10:
            strObj.inputpopularity = '请输入姓名为主播增粉'
            strObj.popularity = '增粉';
            strObj.donatePoints= '增粉次数',//助力积分
                strObj.donate='為主播';
            strObj.donateSuccess= '增粉成功';
            strObj.connectService = '请将本页面截屏后联系您的客服。'
            break;
        case 12:
            strObj.inputpopularity = '请输入姓名为主播投票'
            strObj.popularity = '投票';
            strObj.donateSuccess = '投票成功。';
            strObj.donatePoints = '人气积分';
            strObj.searchIO = true;
            break;
        case 15:
            strObj.connectService = '请将本页面截屏后联系您的助理。';
            break;
        case 17:
            strObj.inputpopularity = '请输入姓名为主播打赏'
            strObj.donateSuccess = '打赏成功';
            strObj.popularity = '打赏';
            strObj.giftgifShow = true;
            break;
        case 19:
            strObj.inputpopularity = '请输入姓名为影片投票'
            strObj.popularity = '投票';
            strObj.integral = '票房票数：';
            strObj.donatePoints = '票房票数'
            strObj.anchorlist = '影片列表';
            strObj.anchorProfile = '影片简历';
            strObj.donate = '助力票房';
            strObj.donateSuccess = '投票成功。';
            break;
        case 20:
            strObj.inputpopularity = '请输入姓名为主播增流'
            strObj.popularity = '增流';
            strObj.donate = '投票主播'
            strObj.donatePoints = '增流积分'
            strObj.donateSuccess = '增流成功'
            strObj.connectService = '请将本页面截屏后联系您的助理。'
            strObj.giftgifShow = false
        break;
        case 25:
            strObj.anchorlist='球星列表';
            strObj.anchorProfile='球星简介';
            strObj.donatePoints='支持热度';
            strObj.inputpopularity='请输入姓名为球星支持热度';
            strObj.popularity='支持';
            strObj.donate='支持球星';
            strObj.donateSuccess='热度支持成功';
            strObj.connectService = '请将本页面截屏后联系您的接待。';
            strObj.giftgifShow=true;
            break;
        case 26:
            strObj.anchorProfile = '剧情简介'
            strObj.popularity = '推广'
            strObj.inputpopularity = '请输入姓名为该剧热评';
            strObj.anchorlist='热门网剧';
            strObj.donatePoints='推广人数';
            strObj.name='恭喜';
            strObj.donate=' ';
            strObj.donateSuccess='推广成功';
            strObj.connectService='请将此页面截图发送给您的接待员。';
            strObj.itemShow= false;
            strObj.giftgifShow=true;
            break
        case 27:
            strObj.anchorlist = '人气列表'
            strObj.anchorProfile = '彩票简介'
            strObj.donatePoints = '人气值'
            strObj.inputpopularity = '请输入姓名为彩票点亮'
            strObj.donateSuccess = '点亮成功 热门彩票'
            strObj.popularity = '点亮'
            strObj.giftgifShow = true;
            break
        case 28:
            strObj.integral = 'คะแนน：';
            strObj.anchorProfile = 'ข้อมูลเพิ่มเติม';
            strObj.anchorlist = 'รายการ';
            strObj.donatePoints = 'คะแนน';
            strObj.popularity = 'สนับสนุน';
            strObj.inputpopularity = 'กรอกชื่อหรือไอดีงานเพื่อสนับสนุนเขา';
            strObj.name = 'คุณ';
            strObj.donate = 'ได้สนับสนุน';
            strObj.donateSuccess = 'สำเร็จแล้ว';
            strObj.connectService = 'กรุณาแคปรายการหน้านี้ติดต่อผู้ดูแลได้เลย';
            strObj.pleaseEnterName = 'กรุณากรอกชื่อ';
            break
        case 30:
            strObj.giftgifShow = true;
            strObj.anchorlist = '基金计划'
            strObj.anchorProfile = '计划简介'
            strObj.donatePoints = '援助基金'
            strObj.inputpopularity ='请输入姓名为此计划增加投入资金'
            strObj.popularity = '援助'
            strObj.donateSuccess = '5元'
            strObj.donate = '增加投入基金'
            strObj.integral = '援助基金';
            strObj.itemShow = false;
            break
    }
}




