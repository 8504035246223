<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import siteCustomized from "@/common/settings";

export default {
  created() {
    siteCustomized();
  }
}
</script>

