import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import {getKol} from "@/common/data";


Vue.config.productionTip = false;

window.mode = 'kol_vote_final';
getKol().then(()=>{ //從 API 取得資料後才渲染畫面
  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app')
} )

