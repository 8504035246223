<template>
  <div class="wrap" v-if="showData">
    <div class="header">
      <div class="title">{{ strObj.title }}</div>
      <div v-if="strObj.url.length === 0" class="rank">
        <div class="item">
          <div class="number">NO.2</div>
          <div class="frame"><img :src="kolListSort[1].avatar" alt="" /></div>
          <div class="name">{{ kolListSort[1].name }}</div>
          <div id="score1" class="score">
            {{ strObj.integral }}{{ kolListSort[1].score }}
          </div>
        </div>
        <div class="item">
          <div class="number">NO.1</div>
          <div class="frame"><img :src="kolListSort[0].avatar" alt="" /></div>
          <div class="name">{{ kolListSort[0].name }}</div>
          <div id="score2" class="score">
            {{ strObj.integral }}{{ kolListSort[0].score }}
          </div>
        </div>
        <div class="item">
          <div class="number">NO.3</div>
          <div class="frame"><img :src="kolListSort[2].avatar" alt="" /></div>
          <div class="name">{{ kolListSort[2].name }}</div>
          <div id="score3" class="score">
            {{ strObj.integral }}{{ kolListSort[2].score }}
          </div>
        </div>
      </div>
      <div  v-else >
        <swiper  v-if="strObj.url.length>1" ref="mySwiper" :options="swiperOption" >
          <swiper-slide v-for="item in strObj.url" :key="item">
            <img class="carousel" :src="item" alt="" >
          </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div v-else>
          <img class="carousel" :src="strObj.url" alt="">
        </div>
      </div>
    </div>
    <div class="container">
      <div class="title">
        {{ strObj.anchorlist }}
        <input
          v-if="strObj.searchIO"
          type="text"
          autocomplete="off"
          placeholder="搜索"
          id="list-search"
          v-model="searchWords"
        />
      </div>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in filterSearch"
          :key="index"
          @click="goPage(item.id)"
        >
          <div class="avatar"><img :src="item.avatar" alt="" /></div>
          <div class="info">
            <div class="name">{{ item.id }}.{{ item.name }}</div>
            <div
              class="introduce"
              @click="showInfo($event, item.name, item.dec)"
            >
              {{ strObj.anchorProfile }}
            </div>
          </div>
          <div class="score">{{ strObj.donatePoints }}：{{ item.score }}</div>
        </div>
      </div>
    </div>

    <div class="popupDec" v-if="isPopupShow">
      <div class="bk" @click="closePopup"></div>
      <div class="content">{{ dec }}</div>
    </div>
  </div>
</template>

<script>
import { strObj } from "@/common/settings";
import router from "@/router/index";
import { kol } from "@/common/data";
//輪播圖套件
import 'swiper/dist/css/swiper.css'
import {swiper,swiperSlide} from "vue-awesome-swiper";

export default {
  name: "homeView",
  components:{
    swiper,
    swiperSlide
  },
  data() {
    return {
      showData: false,
      kolList: kol,
      kolListSort: [],
      dec: "",
      isPopupShow: false,
      strObj: strObj,
      friendListString: "",
      searchWords: "",
      swiperOption:{
        autoplay: {
          delay:3000,
          stopOnLastSlide:false,
          disableOnInteraction:false,
          },
        slidesPerView:1,
        spaceBetween:30,
        loop:true,
        pagination:{
          el:'.swiper-pagination',
          clickable:true
        },
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next'
      }
    };
  },
  computed: {
    filterSearch() {
      return this.kolList.filter((searchResult) =>
        String(searchResult.id + searchResult.name).match(this.searchWords)
      );
    },
  },
  mounted() {
    this.showData = true;
    this.kolListSort = kol.filter(() => true); //複製陣列
    this.kolListSort.sort((a, b) => {
      //陣列排序
      return b.score - a.score; //條件 score
    });
  },
  updated() {
    if (window.siteId === 19) {
      document.getElementById("score1").style.fontSize = "1.1vw";
      document.getElementById("score1").style.fontWeight = "initial";
      document.getElementById("score2").style.fontSize = "1.1vw";
      document.getElementById("score2").style.fontWeight = "initial";
      document.getElementById("score3").style.fontSize = "1.1vw";
      document.getElementById("score3").style.fontWeight = "initial";
    }
  },
  methods: {
    sort(ary) {
      ary.sort((a, b) => {
        return b.score - a.score;
      });
    },
    goPage(id) {
      router.push("/page/" + id);
    },
    showInfo(event, name, dec) {
      event.stopPropagation();
      this.dec = name + "：" + dec;
      this.isPopupShow = true;
    },
    closePopup() {
      this.isPopupShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/less/main.less";
</style>
