<template>
  <div class="wrap page">
    <div class="back" @click="onClickBack"></div>
    <div class="avatar"><img :src="item.avatar" alt="" /></div>
    <div class="introduce">{{ item.name }}：{{ item.dec }}</div>
    <div class="container">
      <template v-if="strObj.giftShows">
        <template>
          <div v-if="showtest" class="introduces">
            {{ strObj.name }} {{ username }} {{ strObj.donate }}
            {{ item.name }} {{ strObj.donateSuccess
            }}{{ strObj.connectService }}
          </div>
        </template>
      </template>
      <div :class="this.sitenumber === 30 ? 'form formnewplace' : 'form'">
        <input
          type="text"
          v-model="username"
          :placeholder="strObj.inputpopularity"
        />
        <div class="submit" @click="send">{{ strObj.popularity }}</div>
      </div>
    </div>
    <div class="gift" v-if="giftShow">
      <div class="box">
        <a href="javascript:;" class="gift_close" @click="closeGift"></a>
        <div v-if="!showtest" class="msg">
          <p v-if="this.sitenumber === 27">
            {{ strObj.name }} <i class="bold">{{ username }}</i>
            {{ strObj.donateSuccess }}
            <i class="bold" v-if="strObj.itemShow">{{ item.name }}</i>
            <br />{{ strObj.connectService }}
          </p>
          <p v-else :class="[this.sitenumber === 28 && 'pfs']">
            {{ strObj.name }} <i class="bold">{{ username }}</i>
            <br :class="[this.sitenumber === 28 ? 'show' : 'hide']" />{{
              strObj.donate
            }}
            <i class="bold" v-if="strObj.itemShow">{{ item.name }}</i>
            {{ strObj.donateSuccess }} <br />{{ strObj.connectService }}
          </p>
        </div>
        <img
          v-if="strObj.giftgifShow"
          :src="require(`@/assets/images/gif/${this.sitenumber}.gif`)"
          alt=""
        />
        <img v-else src="@/assets/images/airplane.gif" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { kol } from "@/common/data.js";
import { strObj } from "@/common/settings";
export default {
  name: "pageView",
  data() {
    return {
      kolList: kol,
      id: parseInt(this.$route.params.id),
      username: "",
      giftShow: false,
      // gifimg: "",
      sitenumber: 0,
      showtest: false,
      strObj: strObj,
    };
  },
  created() {
    this.sitenumber = Number(window.siteId);
    // this.gifimg = require(`@/assets/images/gif/${this.sitenumber}.gif`);
  },
  computed: {
    // strObj() {
    //   return strObj;
    // },
    item: function () {
      return this.kolList.find((element) => element.id === this.id);
    },
  },
  methods: {
    onClickBack() {
      this.$router.back();
    },
    send() {
      if (this.username === "") {
        alert(this.strObj.pleaseEnterName);
        return;
      }
      this.giftShow = true;
      this.strObj.giftShows = true;
      if (window.siteId === 20) {
        this.showtest = true;
      }
    },
    closeGift() {
      this.giftShow = false;
      this.strObj.giftShows = true;
      if (window.siteId === 20) {
        this.showtest = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/less/main.less";
</style>
