import axios from "axios";
import {strObj} from "@/common/settings";

let timestamp = new Date().getTime();
// let sc = Math.floor((timestamp % 1000000));

const setSessionStorage = (res) => {
    window.sessionStorage.setItem('voteAry', res ? res : '')
}

const getSessionStorage = () => {
    return window.sessionStorage.getItem('voteAry') ? window.sessionStorage.getItem('voteAry') : false
}


if(!getSessionStorage()){
    let max = Math.floor((timestamp % 100000000) / 10);//最大值
    let min = Math.floor((timestamp % 100000000) / 10) - 1000000;//最小值
    let numCount = 300;//數量
    let randomNums = Array.from({length: numCount}, () => Math.floor(Math.random() * (max - min + 1)) + min);//產生亂數陣列

    setSessionStorage(randomNums);//寫入 sessionStorage
}



let hostName = window.location.hostname.replace('www.','');
//測試時使用
if(hostName === 'localhost'){
    hostName = "24m4u.com";//測試時套用欲修改文案的客戶站域名
}
// 設定API接口路徑
const adminUrl = 'https://xxxiao1ht.com/';
const getDataUrl = adminUrl+'api/kol/'+hostName;

let kol = [];
function  getKol(){
    return new Promise((resolve)=>{
        axios(getDataUrl).then( res=>{
            if(res.data.code === 1){
                let  data = res.data.data;
                document.title = data[0].site_title;//網站標題
                window.siteId = data[0].id // 站點ID
                strObj.title = data[0].web_title; //網頁標題

                var link = document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = adminUrl+data[0].ico;
                document.getElementsByTagName('head')[0].appendChild(link);

                let ary1 = getSessionStorage().split(",");
                let ary2 = ary1.map(x =>  Math.abs(x) + Math.floor(Math.random() * 100000));
                setSessionStorage(ary2);

                kol = data[1].map((old,index)=>{
                    old.id = Number(old.a_id);
                    old.avatar = adminUrl+old.avatar;//修飾照片Url
                    old.score =  ary2[index];//添加積分
                    return old
                })
                strObj.url=data[2].map(item =>{
                    return adminUrl+item.pic
                })
                resolve(true)
            }
        })
    })
}

export  {kol,
    getKol,
    hostName,}
